<template>
  <div>
    <h5
      class="md:text-2xl text-xl text-center font-bold primary-text leading-10"
    >
      Enter OTP
    </h5>
    <p class="text-center text-grey md:text-lg mt-2">
      We sent an SMS and a WhatsApp message to {{ phoneNumber }}
    </p>
    <OtpInput
      class="mt-8"
      @on-change="handleOnChange"
      @on-complete="handleOnChange"
    />
    <Spinner v-if="verifyLoading" />
    <div class="mt-4 flex" v-else>
      <p
        class="underline text-sm text-brandPurple cursor-pointer"
        v-if="showResendButton"
        @click="resendOtp"
      >
        Resend OTP
      </p>
      <div class="ml-auto" v-else>
        <vue-countdown
          :time="60 * 1000"
          v-slot="{ minutes, seconds }"
          @end="showResendButton = true"
        >
          <span class="text-sm text-grey">{{ minutes }}:{{ seconds }}</span>
        </vue-countdown>
      </div>

      <!-- </div> -->
    </div>
    <Button
      :disabled="disableButton"
      class="mt-8"
      width="w-full"
      @click="handleSubmitOtp"
      text="Complete verification"
      :loading="loading"
    />
    <p
      class="text-center font-semibold text-brandPurple mt-8 cursor-pointer"
      @click="handleNoAccess"
    >
      I don't have access to this number
    </p>
  </div>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import { OtpInput } from "@/UI/Input";
  import { Button } from "@/UI/Button";
  import VueCountdown from "@chenfengyuan/vue-countdown";
  import { KYC_STEPS } from "../../data.js";
  export default {
    components: {
      OtpInput,
      Button,
      Spinner: () => import("@/UI/Spinner"),
      "vue-countdown": VueCountdown,
    },
    props: {
      phoneNumber: {
        type: String,
        default: "",
        required: true,
      },
    },
    data: () => ({
      loading: false,
      verifyLoading: false,
      showResendButton: false,
      user: {
        otp: "",
      },
    }),
    computed: {
      ...mapState({
        bvn: (state) => state?.auth?.bvn?.number,
      }),
      rules() {
        return {
          otp: this.user.otp.length === 6,
        };
      },
      disableButton() {
        return Object.values(this.rules).includes(false);
      },
    },
    mounted() {
      this.handleDelayOtpButton();
    },
    methods: {
      ...mapActions("dashboard", ["handleKycStep", "getDashboardData"]),
      ...mapActions("notification", ["showToast"]),
      ...mapActions("userInfo", ["verifyBvn"]),
      ...mapActions("auth", ["sendBvnOtp"]),
      handleSubmitOtp() {
        this.loading = true;
        this.verifyBvn({
          bvn: this.bvn,
          validateWith: "OTP",
          otp: this.user.otp,
        })
          .then(() => {
            this.getDashboardData().then(() => {
              this.loading = false;
              this.handleKycStep(KYC_STEPS.camera);
            });
          })
          .catch(() => {
            this.loading = false;
          });
      },
      handleOnChange(value) {
        this.user.otp = value;
      },
      handleNoAccess() {
        this.handleKycStep(KYC_STEPS.camera);
      },
      handleDelayOtpButton() {
        setTimeout(() => {
          this.showResendButton = true;
        }, 60000);
      },
      resendOtp() {
        this.verifyLoading = true;
        this.sendBvnOtp(this.bvn)
          .then(() => {
            this.verifyLoading = false;
            this.showToast({
              display: true,
              icon: "success",
              description: "A new otp has been sent to your email",
            });
            this.showResendButton = false;
            this.handleDelayOtpButton();
          })
          .catch(() => {
            this.verifyLoading = false;
          });
      },
    },
  };
</script>
